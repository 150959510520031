/* Reset some default styles */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* Style the container */
.container {
  background-color: #f4f4f4;
  border-radius: 10px;
}

/* Style the form */
form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

/* Style form groups */
.form-group {
  margin-bottom: 10px;
}

/* Style labels */
label {
  display: block;
  font-weight: Normal;
  margin-bottom: 10px;
  margin-top: 15px;
  color: #ca477d;
}

/* Style form inputs and textarea */
.input-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  transition: border-color 0.3s;
}

.input-control:focus {
  outline: none;
  border-color: #ca477d;
}

/* Style the checkbox */
.form-check-label {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  color: #ca477d;
}

.contactheading {
  color: #ca477d;
  font-family: popins;
  padding-top: 20px;
}

.trip-item {
    margin: 1rem 0;
  }
  
  .trip-item__content {
    padding: 0;
  }
  
  .trip-item__info {
    padding: 1rem;
    text-align: center;
  }
  
  .trip-item__image {
    width: 100%;
    height: 12.5rem;
    margin-right: 1.5rem;
  }
  
  .trip-item__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .trip-item__info h2,
  .trip-item__info h3,
  .trip-item__info p {
    margin: 0 0 0.5rem 0;
  }
  
  .trip-item__actions {
    padding: 1rem;
    text-align: center;
    border-top: 1px solid #ccc;
  }
  
  .trip-item__modal-content {
    padding: 0;
  }
  
  .trip-item__modal-actions {
    text-align: right;
  }
  
  .trip-item__actions button,
  .trip-item__actions a {
    margin: 0.5rem;
  }
  
  @media (min-width: 768px) {
    .trip-item__image {
      height: 20rem;
    } 
  }
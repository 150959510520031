.FullFooter{
    background-color:#264059 ;
    color: aliceblue;
}

.footerMenuHeading{
    font-size: 1.2rem;
    font-weight: 500;
    color: #ca477d;
    /* text-decoration: underline; */
    margin: 10px 0px;
    padding-left: 25px;

    
}
.footerLinksRow{
    padding-bottom: 20px;
}
.footerList li {
    list-style: none;
  }
  
  .footerList li a {
    text-decoration: none;
    color: #ffffff; /* Set your desired default color */
  }
  
  .footerList li a:hover {
    color: #ca477d;
    cursor: pointer;
  }


.copyRightRow{
    background-color: #284662;
    height: 30px;
}




.social-icons {
    display: flex;
    justify-content: center;
  }
  
  .social-icons svg {
    font-size: 24px;
    margin: 0 10px;
    color: #ca477d;
  }
  
  .social-icons svg:hover {
    color: #fff; 
    cursor: pointer;
  }
.banner {
  width: 100%;
  height: 60vh;
  background-size: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  position: relative;
}

.page-info {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  font-weight: 500;
}

.banner-content {
  max-width: 600px;
}

.banner-content h1 {
  font-size: 3.5rem;
  margin-bottom: 10px;
}

.banner-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.AboutEcoGoDesc {
  opacity: 0;
  animation: fadeIn 5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-page-image1 {
  max-width: 300px;
  max-height: 300px;
}

.vertical-line {
    position: relative;
  }
  
  .vertical-line::before {
    content: "";
    border-left: 5px solid #CA477D;
    position: absolute;
    height: 88%;
    top: 0;
    left: 0;
  }
  
  .globalFactsImg {
    padding-top: 10px;
    height: 250px;
    width: 250px;
  }


.primary-button {
    padding: 10px 20px;
    margin-bottom: 10px ;
    font-size: 1rem;
    border: none;
    background-color: #CA477D;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6); 
    transition: background-color 0.3s ease-in-out;
  }
  
  .primary-button:hover {
    background-color: #008e72;
    cursor: pointer;
  }

  .secondary-button {
    padding: 10px 20px;
    margin-bottom:50px ;
    font-size: 1rem;
    border: none;
    background-color: #008e72;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6); 

  }
  .secondary-button:hover {
    background-color: #CA477D;
  }